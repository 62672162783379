/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Cổng thông tin
                    <br />
                    Tổng hợp các mức phạt
                  </>
                }
                description="Mức phạt có thể thay đổi tùy theo từng khu vực và các điều kiện cụ thể của từng trường hợp.
Ngoài việc phạt tiền, người vi phạm còn có thể bị tước quyền sử dụng bằng lái xe trong một khoảng thời gian nhất định tùy thuộc vào mức độ vi phạm."
              />
              <RotatingCardBack
                image={bgBack}
                title="Thông tin mới"
                description="Cập nhật luật và các thông tin mới ban hành."
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "Truy cập",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Loại Phạt"
                  description="Phạt Tiền: Hình thức phạt chính áp dụng cho các vi phạm giao thông.
Tước Quyền Sử Dụng Giấy Phép Lái Xe: Áp dụng cho các vi phạm nghiêm trọng, có thể từ vài tháng đến vài năm."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Phương Tiện Giao Thông"
                  description="Ô Tô: Bao gồm xe con, xe tải, xe khách.
Xe Máy: Gồm xe máy hai bánh và xe tay ga.
Xe Đạp: Xe đạp điện và xe đạp thường.
Xe Tải và Xe Khách: Các loại xe tải lớn và xe khách như xe buýt."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Vi Phạm Chính"
                  description="Chạy Quá Tốc Độ
Đỗ Sai Quy Định
Không Thắt Dây An Toàn
Lái Xe Trong Tình Trạng Say Rượu
"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Hình Thức Xử Phạt"
                  description="Xử Phạt Tại Chỗ: Lực lượng chức năng lập biên bản và xử phạt ngay tại hiện trường.
Xử Phạt Qua Hệ Thống Camera: Vi phạm được ghi nhận qua camera và người vi phạm sẽ nhận thông báo sau đó."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
